import type { NextPage } from "next";
import Head from "next/head";
import { publicAssetUrl } from "../shared/helpers/urls";

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>OnlineDoctor</title>
        <meta
          name="description"
          content="Index of the OnlineDoctor doctor pages interface"
        />
        <link rel="icon" href={publicAssetUrl("/favicon_CH-DE.svg")} />
      </Head>
      Doctor pages interface
    </>
  );
};

export default Home;
