import { GeoLocation } from "../types";

interface StartCaseOptions {
  doctor_id?: number;
  lang?: string;
  insurance?: string;
  [key: string]: string | number | undefined;
}

export const getStartCaseURL = (
  tenantId: string,
  options?: StartCaseOptions
) => {
  const queryOptions = !options
    ? ""
    : Object.keys(options)
        .filter((key) => options[key] !== "")
        .map((key) => `${key}=${options[key]}`)
        .join("&");

  return new URL(
    `/t/${tenantId}/case?${queryOptions}`,
    process.env.patientAccessUiBaseUrl
  );
};

export const stringFromQueryParam = (param?: string | string[]) =>
  typeof param === "string" ? param : undefined;

export const publicAssetUrl = (path: string) =>
  process.env.NEXT_PUBLIC_PUBLIC_ASSET_CDN
    ? `${process.env.NEXT_PUBLIC_PUBLIC_ASSET_CDN}${path}`
    : path;

export function toLatLgn(point: GeoLocation) {
  return {
    lat: point.latitude,
    lng: point.longitude,
  };
}

const deg2rad = (deg: number) => deg * (Math.PI / 180);

// src: https://stackoverflow.com/a/27943/1073409
const distanceInKilometers = (p1: GeoLocation, p2: GeoLocation) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(p2.latitude - p1.latitude); // deg2rad below
  const dLon = deg2rad(p2.longitude - p1.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(p1.latitude)) *
      Math.cos(deg2rad(p2.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

export function getClosestPoint(
  point: google.maps.LatLngLiteral,
  points: google.maps.LatLngLiteral[]
) {
  if (points.length == 0) {
    return undefined;
  }
  let closestPoint = points[0];
  let closestPointDistance = distanceInKilometers(
    toGeoLocation(point),
    toGeoLocation(closestPoint)
  );
  for (let i = 1; i < points.length; i++) {
    const distance = distanceInKilometers(
      toGeoLocation(point),
      toGeoLocation(points[i])
    );
    if (distance < closestPointDistance) {
      closestPoint = points[i];
      closestPointDistance = distance;
    }
  }
  return closestPoint;
}

function toGeoLocation(point: google.maps.LatLngLiteral): GeoLocation {
  return {
    latitude: point.lat,
    longitude: point.lng,
  };
}
